import { usePathname, useRouter } from 'next/navigation'
import { useEffect, useState } from 'react'
import { Kicker, LogOutIcon, Popover, ResultModal } from '~elements'
import { RESULT_MODAL_TYPE } from '~enums'
import { getCognitoUserInfo } from '~utils'
import styles from 'components/Header/index.module.scss'
import { Logout } from '~services'

interface ILoggedInInitials {
  isRecipient: boolean
}

export const LoggedInInitials = ({ isRecipient }: ILoggedInInitials) => {
  const router = useRouter()
  const pathName = usePathname()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [userInfo, setUserInfo] = useState<{ initials: string; name: string; email: string } | null>(null)

  const handleModalOpen = () => setIsOpen(true)
  const handleModalClose = () => setIsOpen(false)

  useEffect(() => {
    ;(async () => {
      const cognitoUserInfo = await getCognitoUserInfo()
      if (cognitoUserInfo) {
        setUserInfo(cognitoUserInfo)
      }
    })()
  }, [pathName])

  return userInfo ? (
    <>
      <Popover
        isConfirmationModalOpen={isOpen}
        popoverCTA={
          <button className={styles['logout-cta']} id="logout-cta" tabIndex={0}>
            {userInfo.initials}
          </button>
        }
      >
        <div className={styles['user-info-container']}>
          <div className={styles['user-name-email-container']}>
            <Kicker fontSize="16px" fontWeight="600" text={userInfo.name} textTransform="none" letterSpacing="none" />
            <Kicker fontSize="14px" fontWeight="350" text={userInfo.email} textTransform="none" color="#C6C6C6" letterSpacing="none" />
          </div>
          {!isRecipient && (
            <div className={styles['logout-container']}>
              <button onClick={() => handleModalOpen()} className={styles['logout']} tabIndex={0}>
                <LogOutIcon />
                log out
              </button>
            </div>
          )}
        </div>
      </Popover>
      <ResultModal
        type={RESULT_MODAL_TYPE.WARNING}
        closeBtnText="Cancel"
        actionButtonText="Logout"
        isOpen={isOpen}
        handleModalClose={handleModalClose}
        actionButtonCallback={() => {
          Logout()
          setUserInfo(null)
          router.replace('/login')
        }}
      >
        <Kicker fontSize="16px" fontWeight="600" text="Are you sure you want to logout?" letterSpacing="1px" textTransform="none" />
      </ResultModal>
    </>
  ) : null
}
