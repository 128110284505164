import PlayVideoCommand from 'render-streaming-client/build/command/PlayVideoCommand'
import PauseVideoCommand from 'render-streaming-client/build/command/PauseVideoCommand'
import { PauseIcon, PlayIcon } from '~elements'
import { Dispatch, SetStateAction } from 'react'
import styles from './index.module.scss'
import { usePreviewStore } from 'store'

interface IPlayPauseButton {
  playing: boolean
  streaming: boolean
  setPlaying: Dispatch<SetStateAction<boolean>>
}

export const PlayPauseButton = ({ streaming, playing, setPlaying }: IPlayPauseButton) => {
  const { renderingManager } = usePreviewStore()

  const playVideo = () => {
    const playVideo = new PlayVideoCommand()
    // Use this for debugging.
    // renderingManager?.onCommandResponse(playVideo.name, response => {
    //   console.log(response)
    // })

    renderingManager?.executeCommand(playVideo)
  }

  const pauseVideo = () => {
    const pauseVideo = new PauseVideoCommand()
    // Use this for debugging.
    // renderingManager?.onCommandResponse(pauseVideo.name, response => {
    //   console.log(response)
    // })
    renderingManager?.executeCommand(pauseVideo)
  }

  const handleTogglePlay = () => {
    if (playing) {
      pauseVideo()
      setPlaying(false)
    } else {
      playVideo()
      setPlaying(true)
    }
  }

  return renderingManager && streaming ? (
    <button onClick={handleTogglePlay} className={styles['play-button']} tabIndex={0}>
      {playing ? <PauseIcon /> : <PlayIcon />}
    </button>
  ) : null
}
