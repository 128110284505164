import { useEffect, useState } from 'react'
import { useSearchParams } from 'next/navigation'
import parse, { Element, attributesToProps, DOMNode } from 'html-react-parser'

import { ParagraphProps } from '~interfaces'

export const Paragraph = ({ fontSize, justify = 'left', text: { html }, className }: ParagraphProps) => {
  //solves hydration issue
  const [show, setShow] = useState<boolean>(false)
  const searchParams = useSearchParams()
  const requestCameFrom = searchParams.get('camefrom')

  useEffect(() => {
    setShow(true)
  }, [])

  /* Revisit to make it custom util */
  const parsedHTMLString = parse(html, {
    replace: (domNode: DOMNode) => {
      //@ts-ignore
      const { type, name, attribs, children } = domNode
      if (domNode instanceof Element && type === 'tag' && name === 'a') {
        const props = attributesToProps(attribs)
        if (props && props.href && typeof props.href === 'string' && props.href.startsWith('/')) {
          const [anchor] = children

          //@ts-ignore
          const anchorLabel = anchor?.data
          const requestCameFromUrl = requestCameFrom ? `${props.href}?camefrom=${requestCameFrom}` : props.href
          const updatedProps = {
            ...props,
            href: requestCameFromUrl
          }

          return <a {...updatedProps}>{anchorLabel}</a>
        }
      }
    }
  })

  return (
    <>
      {show && html && (
        <div className={`paragraph-container ${className ? className : ''}`}>
          <div className={`justify-${justify} full-width ${fontSize ? 'font-size-' + fontSize : ''}`}>{parsedHTMLString}</div>
        </div>
      )}
    </>
  )
}
