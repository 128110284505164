import { useRouter } from 'next/navigation'
import { Kicker, LeftChevron, ResultModal } from '~elements'
import { RESULT_MODAL_TYPE } from '~enums'

import { usePreviewStore } from '~store'
import { PREVIEW_PATH } from '~constants'

import styles from './index.module.scss'

interface IExitPreviewButton {
  isRecipient: boolean
}

export const ExitPreviewButton = ({ isRecipient }: IExitPreviewButton) => {
  const router = useRouter()
  const { displayExitModal, setDisplayExitModal } = usePreviewStore()

  const handleModalOpen = () => setDisplayExitModal(true)
  const handleModalClose = () => setDisplayExitModal(false)

  return (
    <>
      {!isRecipient && (
        <button className={styles['exit-preview-button']} onClick={handleModalOpen} tabIndex={0}>
          <LeftChevron />
        </button>
      )}
      <ResultModal
        type={RESULT_MODAL_TYPE.WARNING}
        closeBtnText="Cancel"
        actionButtonText="Exit"
        isOpen={displayExitModal}
        handleModalClose={handleModalClose}
        actionButtonCallback={() => {
          // router.push(PREVIEW_PATH).then(() => {
          //   handlePreviewDisconnect({
          //     renderingManager,
          //     resetRenderingManager,
          //     type: PREVIEW_DISCONNECT_TYPE.USER_PRESSED_EXIT_PREVIEW
          //   })
          // })
          router.push(PREVIEW_PATH)
        }}
      >
        <Kicker fontSize="16px" fontWeight="600" text="Are you sure you want to exit Preview?" letterSpacing="1px" textTransform="none" />
      </ResultModal>
    </>
  )
}
