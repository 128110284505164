import { Dispatch, ForwardedRef, forwardRef, SetStateAction, useRef, useState } from 'react'
import { DeleteFile, DownloadSelectedFile, EllipsisIcon, ManageRecipientsModal, PreviewSelectedFile, ShareFile, TheatersFillIcon } from '~elements'
import { useWindowSize } from '~hooks'
import { PREVIEW_PAGE_BREAKPOINT } from '~constants'
import { capitalizeFirstLetter, formatDate, getInitialsByFNameLName } from '~utils'
import { IPreviewVideo } from '~interfaces'
import styles from '../ListView/index.module.scss'

interface SingleRecordProps {
  record: IPreviewVideo
  selectedRecord?: IPreviewVideo
  setGenericError: Dispatch<SetStateAction<string | null>>
  setDropdownOpen: (data: boolean) => void
  dropdownOpen: boolean
  setDeleteModalOpen: Dispatch<SetStateAction<boolean>>
}

export const SingleRecord = forwardRef((props: SingleRecordProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { record, selectedRecord, setGenericError, setDropdownOpen, dropdownOpen, setDeleteModalOpen } = props
  const { windowSize } = useWindowSize('lg', '<=')
  const isBelowBreakpointLg = windowSize <= PREVIEW_PAGE_BREAKPOINT
  const dropdownButtonRef = useRef<HTMLButtonElement | null>(null)
  const [manageRecipientsModalOpen, setManageRecipientsModalOpen] = useState<boolean>(false)

  const openManageRecipientsModal = () => {
    setManageRecipientsModalOpen(true)
  }

  const shareUrl = `${new URL(window.location.href).toString()}video?id=${record.id}`

  const getTitle = (title: string) => {
    const [experienceId, ...rest] = title.split('_')
    const [versionWithFileExt, ...remaining] = [...rest.reverse()]
    const DRPGG = remaining.reverse().join('_')

    return (
      <>
        <span data-testid="experience-id">{experienceId}</span>
        <span data-testid="DRPGG">{`_${DRPGG}_`}</span>
        <span data-testid="versionWithFileExt">{versionWithFileExt}</span>
      </>
    )
  }

  return (
    <>
      <tr>
        <td>
          <div className={styles['title-container']}>
            <div>
              <TheatersFillIcon />
            </div>
            <div data-type="title">{getTitle(record.title)}</div>
          </div>
        </td>
        {!isBelowBreakpointLg && (
          <>
            <td>{`${record.filesize} MB`}</td>
            <td>{formatDate(record.uploaded_on)}</td>
            <td>
              <div className={styles['uploaded-by-initials-container']}>
                <div className={styles['uploaded-by-initials']}>{getInitialsByFNameLName(record.firstname, record.lastname)}</div>
                <div className={styles['uploaded-by-name']}>
                  {`${capitalizeFirstLetter(record.firstname)} ${capitalizeFirstLetter(record.lastname)}`}
                </div>
              </div>
            </td>
          </>
        )}
        <td>
          <div className={styles['container']}>
            <button
              className={styles['dropdown-button']}
              value={record.title}
              ref={dropdownButtonRef}
              id={`dropdown-button`}
              data-testid="more-button"
              tabIndex={0}
            >
              <EllipsisIcon />
            </button>
            {dropdownOpen && selectedRecord?.title === record.title ? (
              <div className={styles['dropdown-menu']} ref={ref}>
                {process.env.NEXT_PUBLIC_HIDE_SHARE ? null : (
                  <ShareFile
                    record={record}
                    closeDropdownMenu={() => setDropdownOpen(false)}
                    displayError={setGenericError}
                    openManageRecipientsModal={openManageRecipientsModal}
                  />
                )}
                <DownloadSelectedFile record={record} closeDropdownMenu={() => setDropdownOpen(false)} displayError={setGenericError} />
                <DeleteFile openDeleteModal={setDeleteModalOpen} closeDropdownMenu={() => setDropdownOpen(false)} />
              </div>
            ) : null}
            <PreviewSelectedFile record={record} displayError={setGenericError} />
          </div>
        </td>
      </tr>
      {selectedRecord && (
        <ManageRecipientsModal
          fileName={selectedRecord?.title}
          videoId={Number(selectedRecord?.id)}
          isOpen={manageRecipientsModalOpen}
          handleModalClose={() => setManageRecipientsModalOpen(false)}
          shareUrl={shareUrl}
        />
      )}
    </>
  )
})

SingleRecord.displayName = 'SingleRecord'
